import * as React from 'react'
import {Breadcrumb} from '../components/breadcrumb'

import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => {
  const infoSection = (
    <>
      <div className="col-md-5">
        <h1>404 Page</h1>
      </div>
      <div className="col-md-7">
        <p>Hier ist wohl was schief gegangen</p>
      </div>
    </>
  )

  return (
    <Layout infoSection={infoSection}>
      <Seo title="404: Not found" />
      <Breadcrumb crumbs={[{label: '404'}]} />

      <section className="content_info">
        <div className="paddings">
          <div className="container wow fadeInUp">
            <div className="row page-error">
              <h1>
                404 <i className="fa fa-unlink"></i>
              </h1>
              <hr className="tall" />
              <p className="lead">
                Die gewünschte Seite scheint es nicht zu geben.
              </p>
              <a href="index.html" className="button">
                Zurück zur Startseite
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
