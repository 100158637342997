import {Link} from 'gatsby'
import React from 'react'

export interface Crumb {
  label: string
  link?: string
  title?: string
}

export interface BreadcrumbProps {
  crumbs: Crumb[]
}

export const Breadcrumb = ({crumbs}: BreadcrumbProps) => {
  const renderLink = (crumb: Crumb) => {
    if (crumb.link) {
      return [
        <li key={`${crumb.label}-label`}>/</li>,
        <Link
          key={`${crumb.label}-link`}
          to={crumb.link}
          title={crumb.title ?? ''}
        >
          {crumb.label}
        </Link>,
      ]
    }
    return [
      <li key={`${crumb.label}-label`}>/</li>,
      <li key={`${crumb.label}-link`}>{crumb.label}</li>,
    ]
  }
  return (
    <div className="crumbs border_bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul>
              <li key="home">
                <Link to="/" title="Zurück zur Startseite">
                  Home
                </Link>
              </li>
              {crumbs.map(renderLink)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
